import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Thanks https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering
const useFocus = () => {
    const htmlElRef = React.useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

const TerminalInput = (props) => {
    //const [disableSend, setDisableSend] = React.useState(false)
    const [inputFocus, setInputFocus] = useFocus()
    const [checked, setChecked] = useState(false)
    const [running, setRunning] = useState(false)
    const [input, setInput] = useState('')
    const checkedRef = React.useRef(null)
    const timerRef = React.useRef(null)
    const runningRef = React.useRef(false)
    checkedRef.current = checked;

    React.useEffect(() => {
        setInputFocus()
        //console.log('focused')
    }, [props.input])

    // console.log({checked})

    const handleChange = useCallback(event => {
        setChecked(event.target.checked);
    }, []);

    const handleSend = (cmd) => {
        if (!cmd && runningRef.current) {
            runningRef.current = false;
            setRunning(false)
            timerRef.current && clearTimeout(timerRef.current);
            console.log('clear')
            return;
        }
        props.send(cmd);
        if (checkedRef.current) {
            setRunning(true)
            runningRef.current = true;
            !cmd && timerRef.current && console.log('clear1');
            !cmd && timerRef.current && clearTimeout(timerRef.current);
            cmd = cmd || input;
            cmd && (timerRef.current = setTimeout(() => {
                console.log('handleSend', cmd);
                // props.setInput(cmd + 'x');
                // props.setInput(cmd);
                timerRef.current = setTimeout(() => handleSend(cmd), 100);
            }, 2000));
        } else {
            setRunning(false)
            runningRef.current = false;
            timerRef.current && clearTimeout(timerRef.current);
            console.log('clear2')
        }
    };

    const handleInput = useCallback(e => {
        setInput(e.target.value);
        props.setInput(e.target.value);
    }, [props.setInput]);

    return (
        <Grid container spacing={0}>
            <Grid item sx={{
                width: 'calc(100% - 8rem)',
                paddingRight: '.5em',
            }}>
                <TextField
                    label='Input'
                    variant='outlined'
                    onChange={handleInput}
                    value={props.input}
                    fullWidth
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                    //disabled={disableSend}
                    autoComplete='off'
                    autoFocus
                    inputRef={inputFocus}
                />
            </Grid>
            <Grid item sx={{
                width: '8rem',
            }}>
                <Button sx={{
                    height: 56,
                    color: '#fff',
                    '&.Mui-disabled': {
                        color: '#aaa'
                    },
                    '&.MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
                        borderColor: '#777'
                    }
                }}
                    variant='contained'
                    disableElevation
                    onClick={() => {handleSend()}}
                    //disabled={disableSend}
                    fullWidth
                >{running? 'Stop' : 'Send'}</Button>
                <FormControlLabel control={<Checkbox onChange = {handleChange}
                                                     color="primary"
                                                     checked={checked}
                />} label="Repeat" />
            </Grid>
        </Grid>
    )
}

TerminalInput.propTypes = {
    input: PropTypes.string,
    setInput: PropTypes.func,
    send: PropTypes.func,
}

export default TerminalInput