import React, {useCallback, useEffect} from 'react'

import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import Terminal from './components/Terminal'
import Settings from './components/Settings'
import ErrorMessage from './components/ErrorMessage'

// import Serial from './modules/Serial'
import { setCookie, getCookie } from './modules/cookie.js'
import serial from "./serial";

const loadSettings = () => {
  let settings = {
    baudRate: 115200,
    lineEnding: '\\r\\n',
    echoFlag: true,
    timeFlag: false,
    ctrlFlag: true,
  }

  const cookieValue = getCookie('settings');

  if (cookieValue) {
    try {
      const cookieJSON = JSON.parse(cookieValue)

      if ('baudRate' in cookieJSON) settings.baudRate = cookieJSON.baudRate
      if ('lineEnding' in cookieJSON) settings.lineEnding = cookieJSON.lineEnding
      if ('echoFlag' in cookieJSON) settings.echoFlag = cookieJSON.echoFlag
      if ('timeFlag' in cookieJSON) settings.timeFlag = cookieJSON.timeFlag
      if ('ctrlFlag' in cookieJSON) settings.ctrlFlag = cookieJSON.ctrlFlag
    } catch (e) {
      console.error(e)
    }
  }

  //saveSettings(settings)
  return settings
}

function App() {
  // Serial Module
  const [port, setPort] = React.useState(null)

  // Connection Flag
  const [connected, setConnected] = React.useState(false)

  // Receive Buffer
  const [received, setReceived] = React.useState({ time: new Date(), value: '' })

  // Connect/Disconnect Toast Open
  const [toast, setToast] = React.useState({ open: false, severity: 'info', value: '' })

  // Settings Window Open
  const [settingsOpen, setSettingsOpen] = React.useState(false)

  // Error Window
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  // Settings
  const [settings, setSettings] = React.useState(loadSettings())

  const saveSettings = (newSettings) => {
    // serial.setBaudRate(newSettings.baudRate)
    setSettings(newSettings)
    setCookie('settings', JSON.stringify(newSettings), 365)
  }

  const closeToast = () => {
    setToast({ ...toast, open: false })
  }

  const connectPort = useCallback((port) => {
    console.log('Connecting to ' + port.device_.productName + '...');

    port.connect().then(() => {
      console.log(port);
      setConnected(true);
      setToast({ open: true, severity: 'success', value: 'Connected to ' + port.device_.productName + ' 🚀' })
      console.log('Connected');
      !connected && setReceived({
        time: new Date(),
        value: 'Connected to ' + port.device_.productName + "\r\n"
      });

      port.onReceive = data => {
        let textDecoder = new TextDecoder();
        const value = textDecoder.decode(data);
        setReceived({
          time: new Date(),
          value: `${value}`,
        });
      }

      port.onReceiveError = error => {
        console.log('Receive error: ' + error);
      };

      setPort(port);
    }, error => {
      setToast({ open: true, severity: 'error', value: 'Connection error: ' + error});
      setPort(null);
      setConnected(false)
    });
  }, [connected]);

  const disconnect = useCallback((showToast) => {
    if (port) {
      const productName = port.device_.productName;
      port.disconnect();
      setPort(null);
      setConnected(false);
      console.log('Disconnected ' + productName, showToast);
      if (showToast) {
        setToast({ open: true, severity: 'success', value: 'Disconnected ' + productName })
      }
    }
  }, [port]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      disconnect();
      // event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [disconnect]);

  const connect = useCallback(() => {
    if (port) {
      disconnect();
    } else {
      serial.requestPort().then(selectedPort => {
        connectPort(selectedPort);
      }).catch(error => {
        console.log('Connection error: ' + error);
      });
    }
  }, [port, connectPort, disconnect]);

  const handleSend = useCallback((str) => {
    const map = {
      'None': '',
      '\\r': '\r',
      '\\n': '\n',
      '\\r\\n': '\r\n',
    }

    if (port) {
      const textEncoder = new TextEncoder();
      port.send(textEncoder.encode(`${str}${map[settings.lineEnding]}`)).catch(error => {
        console.log('Send error: ' + error);
        setReceived({
          time: new Date(),
          value: ('Send error: ' + error) + "\r\n"
        });
      });
      // console.log({map})
      // console.log({settings})
      console.log(JSON.stringify(`${str}${map[settings.lineEnding]}`))
      // console.log(textEncoder.encode(`${str}${map[settings.lineEnding]}`))
    }
  }, [port]);

  const handleRawSend = useCallback((byte) => {
    if (port) {
      const textEncoder = new TextEncoder();
      port.send(textEncoder.encode(byte)).catch(error => {
        console.log('Send error: ' + error);
        setReceived({
          time: new Date(),
          value: ('Send error: ' + error) + "\r\n"
        });
      });
    }
  }, [port]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      {/* Header */}
      <Header
        connected={connected}
        disconnect={disconnect}
      />

      {/* Homepage or Terminal */}
      {connected ?
        <Terminal
          key="terminal"
          received={received}
          send={handleSend}
          sendRaw={handleRawSend}
          openSettings={() => setSettingsOpen(true)}
          echo={settings.echoFlag}
          time={settings.timeFlag}
          ctrl={settings.ctrlFlag}
          clearToast={() => setToast({ open: true, severity: 'info', value: 'History cleared 🧹' })}
        />
        :
        <Home
          connect={connect}
          supported={() => 'usb' in navigator}
          openSettings={() => setSettingsOpen(true)}
        />
      }

      {/* Settings Window */}
      <Settings
        open={settingsOpen}
        close={() => setSettingsOpen(false)}
        settings={settings}
        save={saveSettings}
        openPort={connected}
        saveToast={() => setToast({ open: true, severity: 'success', value: 'Settings saved ✨' })}
      />

      {/* (Dis)connected Toast */}
      <Snackbar open={toast.open} autoHideDuration={4000} onClose={closeToast}>
        <Alert onClose={closeToast} severity={toast.severity}>
          {toast.value}
        </Alert>
      </Snackbar>

      {/* Error Message Window */}
      <ErrorMessage
        open={errorOpen}
        close={() => setErrorOpen(false)}
        message={errorMessage}
      />

      {/* Footer */}
      {/*<Footer />*/}
    </Box>
  );
}

export default App