import React from 'react'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddBoxIcon from '@mui/icons-material/AddBox'
import PowerOffIcon from '@mui/icons-material/PowerOff'
import logo from '../icons/usb-square.svg'

const Header = (props) => {
    return (
        <AppBar
            position='static'
            sx={{
                background: '#000',
                // backgroundImage: "linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%)",
                backgroundImage: "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
            }}
        >
            <Toolbar>
                <img
                    src={logo}
                    alt='Logo'
                    height='30px'
                />

                <Typography
                    variant='h6'
                    component='h1'
                    noWrap
                    sx={{
                        flexGrow: 1,
                        fontFamily: 'Bungee',
                    }}
                >
                    &nbsp;&nbsp;WebUsb Terminal
                </Typography>

                {props.connected ?
                  <Button
                    sx={{color: '#fff'}}
                    onClick={() => props.disconnect(true)}
                    title="Disconnect"
                  >
                      <PowerOffIcon/>
                  </Button>
                  : ''
                }

                <Button
                  sx={{ color: '#fff' }}
                  target='_blank'
                  href='#'
                  title="New Terminal"
                >
                    <AddBoxIcon/>
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default Header